var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { nav: "", color: "transparent" } },
    [
      _vm._l(_vm.groups, function (group) {
        return _c(
          "v-list-group",
          {
            key: group.type,
            class: {
              "d-none":
                !_vm.hasGroup(_vm.filters, group.type) && group.type !== "More",
              "text--disabled":
                group.type === "More" &&
                !_vm.$store.getters.hasFeature("SEARCH_FILTERS_ADVANCED"),
            },
            attrs: {
              "sub-group": "",
              value: group.expanded,
              disabled:
                group.type === "More" &&
                !_vm.$store.getters.hasFeature("SEARCH_FILTERS_ADVANCED"),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item-content",
                        [
                          group.title
                            ? _c("v-list-item-title", [
                                _vm._v(_vm._s(group.title)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      group.type === "More"
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: !_vm.$store.getters.hasFeature(
                                      "SEARCH_FILTERS_ADVANCED"
                                    ),
                                  },
                                  on: {
                                    click: [
                                      function ($event) {
                                        _vm.configureFilterPopupVisible =
                                          !_vm.configureFilterPopupVisible
                                      },
                                      function ($event) {
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [_c("v-icon", [_vm._v("fa-light fa-plus")])],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      group.type === "More" &&
                      !_vm.$store.getters.hasFeature("SEARCH_FILTERS_ADVANCED")
                        ? _c(
                            "v-list-item-action",
                            [
                              _c("FeatureBadge", {
                                attrs: {
                                  inline: "",
                                  hasFeature: _vm.$store.getters.hasFeature(
                                    "SEARCH_FILTERS_ADVANCED"
                                  ),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      group.type === "Goods" || group.type === "Services"
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: [
                                      function ($event) {
                                        _vm.niceClassFilterPopupVisible =
                                          !_vm.niceClassFilterPopupVisible
                                      },
                                      function ($event) {
                                        $event.stopPropagation()
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("fa-light fa-magnifying-glass"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          [
            _c(
              "v-list-item-group",
              {
                attrs: {
                  multiple: "",
                  "value-comparator": _vm.selectedFilterComparer,
                },
                on: { change: _vm.fireApplyFilters },
                model: {
                  value: _vm.selectedFilters,
                  callback: function ($$v) {
                    _vm.selectedFilters = $$v
                  },
                  expression: "selectedFilters",
                },
              },
              _vm._l(
                _vm.filterByGroupAndSort(_vm.filters, group.type),
                function (filter, index) {
                  return _c("v-list-item", {
                    key: filter.value + "_" + index,
                    staticClass: "very-dense",
                    attrs: {
                      dense: "",
                      value: _vm.getExistingOrNewFilterItemValue(filter),
                      disabled: filter.hasFeature === false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var active = ref.active
                            return [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-2" },
                                [
                                  _c(
                                    "v-scroll-x-transition",
                                    [
                                      active
                                        ? _c(
                                            "v-icon",
                                            { attrs: { size: "16" } },
                                            [_vm._v(" fa-light fa-check ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              filter.icon
                                ? _c(
                                    "v-list-item-icon",
                                    { staticClass: "mr-3" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: filter.color,
                                          },
                                        },
                                        [_vm._v(_vm._s(filter.icon))]
                                      ),
                                    ],
                                    1
                                  )
                                : filter.image
                                ? _c(
                                    "v-list-item-icon",
                                    {
                                      staticClass: "mr-3",
                                      attrs: { tile: "" },
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "my-auto",
                                        attrs: {
                                          src: filter.image,
                                          contain: "",
                                          "max-height": "17",
                                          "max-width": "17",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        disabled: !filter.toolTip,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-list-item-title",
                                                  _vm._g(
                                                    _vm._b(
                                                      { staticClass: "mb-0" },
                                                      "v-list-item-title",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(filter.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(filter.toolTip)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              filter.count || filter.count === 0
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "px-2",
                                          attrs: {
                                            label: "",
                                            "x-small": "",
                                            dark: "",
                                            color: "tertiary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(filter.count) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              filter.hasFeature === false
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c("FeatureBadge", {
                                        attrs: {
                                          inline: "",
                                          small: "",
                                          hasFeature:
                                            filter.hasFeature !== false,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }
              ),
              1
            ),
          ],
          1
        )
      }),
      _c("ConfigureBrandFilterPopup", {
        on: {
          addApplicationDateFilter: _vm.addApplicationDateFilter,
          addRegistrationDateFilter: _vm.addRegistrationDateFilter,
          addOppositionPeriodEndDateFilter:
            _vm.addOppositionPeriodEndDateFilter,
        },
        model: {
          value: _vm.configureFilterPopupVisible,
          callback: function ($$v) {
            _vm.configureFilterPopupVisible = $$v
          },
          expression: "configureFilterPopupVisible",
        },
      }),
      _c("NiceClassFilterPopup", {
        attrs: {
          items: _vm.niceClassFilters,
          selected: _vm.selectedNiceClasses,
        },
        on: { selectedChanged: _vm.selectedNiceClassesChanged },
        model: {
          value: _vm.niceClassFilterPopupVisible,
          callback: function ($$v) {
            _vm.niceClassFilterPopupVisible = $$v
          },
          expression: "niceClassFilterPopupVisible",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }