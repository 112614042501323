



































































































































































































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyObject, BusyList } from '@/models/Busy';
import { BrandQueriesService, BrandQuerySession, BrandQuery, BrandQueryResult, MasterDataItem, StoredBrandQuery, User } from '@/api/braendz';
import { SearchableField, FilterableField, FacetableField, BrandQueryMode } from '@/models/Query';
import { AccountInfo } from "@azure/msal-browser";
import { RobotOption, TwitterCardOption } from '@/models/MetaTags';
import { getLocalesString } from '@/models/Locale';
import { blobToBase64Async } from '@/models/Blob';

import ImagePicker from '@/components/ImagePicker.vue';

import BrandNameCombobox from '@/components/Inputs/BrandNameCombobox.vue';
import SearchTextField from '@/components/Inputs/SearchTextField.vue';

import VerticalBrandTileGrid from '@/components/Brands/VerticalBrandTileGrid.vue';
import HorizontalBrandTileGrid from '@/components/Brands/HorizontalBrandTileGrid.vue';
import BrandTable from '@/components/Brands/BrandTable.vue';
import LoadingArea from '@/components/Spinner/LoadingArea.vue';
import ConfigureStoredBrandQueryPopup from '@/components/StoredBrandQueries/ConfigureStoredBrandQueryPopup.vue';
import BrandFilterTreeView, { Comparator, FilterItem } from '@/components/Brands/BrandFilterTreeView.vue';
import FeatureBadge from '@/components/Features/FeatureBadge.vue';

@Component({
  components: {
    BrandNameCombobox,
    SearchTextField,
    ImagePicker,
    LoadingArea,
    VerticalBrandTileGrid,
    HorizontalBrandTileGrid,
    BrandTable,
    BrandFilterTreeView,
    ConfigureStoredBrandQueryPopup,
    FeatureBadge
  },
  metaInfo() {
    return {
      title: this.$i18n.t("brandSearch.title").toString(),
      meta: [
        { name: 'robots', content: [RobotOption.NoIndex, RobotOption.NoFollow].join(',') },

        // Open Graph: Facebook, Instagram, WhatsApp, LinkedIn, Xing, Twitter:
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: this.$i18n.t("brandSearch.metaTags.title").toString() },
        { property: 'og:description', content: this.$i18n.t("brandSearch.metaTags.description").toString() },
        { property: 'og:image', content: `${window.location.origin}${require('@/assets/logos/braendz-logo-tm-blue.png')}` },
        { property: 'og:locale', content: this.$i18n.locale },
        { property: 'og:locale:alternate', content: getLocalesString(',') },
        { property: 'og:site_name', content: this.$i18n.t("metaTags.title").toString()},

        // Twitter:
        { property: 'twitter:card', content: TwitterCardOption.SummaryLargeImage },
        { property: 'twitter:site', content: `@${process.env.VUE_APP_TWITTER_ACCOUNT}` }
      ]
    };
  }
})
export default class Search extends Vue {
  // Fields
  public searchInputBrandName = "";
  public searchInputRegistrationNumber = "";
  public logo: Blob | null = null;
  public searchInputOwner = "";
  public searchMode: 'Name' | 'Logo' | 'NameAndLogo' | 'RegistrationNumber' | 'Owner' = 'Name';

  public showFilters = !this.$vuetify.breakpoint.smAndDown;
  public scrollPositionY = 0;
  public saveNewQueryVisible = false;

  public activeQueryResultView: string | undefined = "vertical-view";

  public newStoredBrandQuery: StoredBrandQuery | null = null;
  public currentBrandQuery: BrandQuery | null = null;
  public brandQueryResult = new BusyObject<BrandQueryResult>();

  public pageSize = 25;
  public currentPage = 1;

  public selectedFilters: FilterItem[] = [];

  public pageSizes = [ 10, 25, 50, 75, 100];

  // Getter:
  public get userAccount(): AccountInfo | null {
    return this.$store.state.userAccount;
  }

  public get numberOfPages(): number | undefined {
    return Math.ceil((this.brandQueryResult.object?.totalCount ?? 0) / this.pageSize);
  }

  public get searchInputComplete(): boolean {
    switch(this.searchMode) {
      case 'Name':
        return !!this.searchInputBrandName;
      case 'Logo':
        return !!this.logo;
      case 'NameAndLogo':
        return !!this.searchInputBrandName && !!this.logo;
      case 'RegistrationNumber':
        return !!this.searchInputRegistrationNumber;
      case 'Owner':
        return !!this.searchInputOwner;
      default:
        return false;
    }
  }

  // Watchers & Event Handlers:
  @Watch('searchInputBrandName')
  public onSearchInputChanged(): void {
    this.resetPage();

    if (this.$route.query.q !== this.searchInputBrandName) {
      this.$router.replace({ query: { q: this.searchInputBrandName } });
    }
  }

  // Component Lifecycle:
  public mounted(): void {
    this.$store.dispatch("updateMasterData");

    // Execute search automatically if query paramter was set:
    if(this.$route.query.q) {
      this.searchInputBrandName = this.$route.query.q as string;
      this.executeNewSearch();
    }
  }

  public created() {
    this.updateScrollPosition();
    window.addEventListener('scroll', this.updateScrollPosition);
  }

  public beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrollPosition);
  }

  // Methods
  public async createQuerySession(newSession: boolean): Promise<BrandQuerySession> {
    const brandQuery = {
      terms: [],
      size: this.pageSize,
      skip: newSession ? 0 : this.pageSize * (this.currentPage - 1),
      filters: [],
      facets: [FacetableField.RegistrationOfficeCode, FacetableField.BrandStateCategory, FacetableField.BrandState, FacetableField.BrandType, `${FacetableField.NiceClasses},count:50`],
      scoring: { enabled: true }
    } as BrandQuery;

    if(this.searchMode === 'RegistrationNumber') {
      // Registernumber filter
      brandQuery.mode = BrandQueryMode.Exact;
      brandQuery.terms?.push({ field: SearchableField.RegistrationNumber, value: this.searchInputRegistrationNumber});
      brandQuery.terms?.push({ field: SearchableField.ApplicationNumber, value: this.searchInputRegistrationNumber});
    }
    else if(this.searchMode === 'Owner') {
      brandQuery.mode = BrandQueryMode.Default;
      brandQuery.terms?.push({ field: SearchableField.OwnerAddress, value: this.searchInputOwner});
    }
    else {
      // Default: Brand name and/or Logo
      brandQuery.mode = BrandQueryMode.Default;

      if((this.searchMode === 'Name' || this.searchMode === 'NameAndLogo') && this.searchInputBrandName) {
        // Add a term for the Brand-Name field:
        brandQuery.terms?.push({ field: SearchableField.Name, value: this.searchInputBrandName});

        // Add a term for the logo text field
        brandQuery.terms?.push({ field: SearchableField.BrandLogoText, value: this.searchInputBrandName});
      }

      if((this.searchMode === 'NameAndLogo' || this.searchMode === 'Logo') && this.logo) {
        // Add the logo
        brandQuery.logo = await blobToBase64Async(this.logo);
      }
    }

    // Configure Filters:
    for (const filter of this.selectedFilters) {
      brandQuery.filters?.push({ field: filter.field, value: filter.value, comparator: filter.comparator });
    }

    // Document created query (e.g. to save or create a new StoredBrandQuery):
    this.currentBrandQuery = brandQuery;

    return { 
      id: newSession ? null : this.brandQueryResult.object?.sessionId, 
      query: brandQuery 
    } as BrandQuerySession;

  }

  // Executes a new Search:
  public async executeNewSearch(): Promise<void> {

    // Do not execute a search without input.
    if(!this.searchInputComplete) {
      return;
    }

    // Clear all filters:
    this.clearFilters();

    // Set page to 1:
    this.resetPage();

    // Create Query:
    const brandQuerySession = await this.createQuerySession(true);

    // Execute Search:
    await this.brandQueryResult.create(async () => {
        return await BrandQueriesService.executeBrandQuery(brandQuerySession);
    });

    this.$store.dispatch('addFeatureUsage', 'BRAND_SEARCH');
  }

  // Executes the search again for the changed page:
  public async changePage() {
    // Create Query:
    const brandQuerySession = await this.createQuerySession(false);

    // Execute Search:
    await this.brandQueryResult.create(async () => {
      return await BrandQueriesService.executeBrandQuery(brandQuerySession);
    });

    // Scroll up:
    this.scrollToSearchResults();
  }

  // Executes the search again for the changed filters:
  public async applyFilters() {
    // Go to page 1 / reset the page:
    this.resetPage();

    // Create Query:
    const brandQuerySession = await this.createQuerySession(false);

    // Execute Search:
    await this.brandQueryResult.create(async () => {
      return await BrandQueriesService.executeBrandQuery(brandQuerySession);
    });
  }

  public clearFilters(): void {
    const brandFilterTreeView = this.$refs.filterTreeView as BrandFilterTreeView
    if(brandFilterTreeView) {
      brandFilterTreeView.resetSelectedFilters();
    }
    else {
      this.selectedFilters = [];
    }
  }

  public resetPage(): void {
    this.currentPage = 1;
  }

  public scrollToPaging() {
    this.$vuetify.goTo("#pagination", { duration: 500, easing: 'easeOutQuad' });
  }

  public scrollToSearchResults() {
    this.$vuetify.goTo("#search-results", { duration: 500, easing: 'easeOutQuad' });
  }

  public updateScrollPosition() {
    this.scrollPositionY = Math.round(window.pageYOffset);
  }

  public removeSelectedFilter(item: FilterItem): void {
    const index = this.selectedFilters.indexOf(item);
    if(index >= 0) {
      this.selectedFilters.splice(index, 1);
      this.applyFilters();
    }
  }

  public openSaveNewQuery(): void {
    if(this.currentBrandQuery) {
      this.newStoredBrandQuery = {
        name: '',
        settings: {
          query: this.currentBrandQuery,
        },
        ownerMailAddress: (this.$store.state.user as BusyObject<User>)?.object?.mailAddress ?? ''
      }
      this.saveNewQueryVisible = true;
    }
  }
}
