var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _vm.brandQueryResult.object
      ? _c(
          "section",
          { attrs: { id: "results" } },
          [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "d-flex flex-column",
                            attrs: {
                              flat: "",
                              tile: "",
                              color: "transparent",
                              width: "100%",
                            },
                          },
                          [
                            _c(
                              "v-toolbar",
                              { attrs: { dense: "", flat: "" } },
                              [
                                _c("v-toolbar-title", [_vm._v(" Ergebnisse ")]),
                                _c("v-spacer"),
                                _c(
                                  "v-btn-toggle",
                                  {
                                    attrs: { dense: "", group: "" },
                                    model: {
                                      value: _vm.activeQueryResultView,
                                      callback: function ($$v) {
                                        _vm.activeQueryResultView = $$v
                                      },
                                      expression: "activeQueryResultView",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          value: "vertical-view",
                                          text: "",
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "fa-light fa-distribute-spacing-horizontal"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          value: "horizontal-view",
                                          text: "",
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "fa-light fa-distribute-spacing-vertical"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.activeQueryResultView === "vertical-view"
                              ? _c("VerticalBrandTileGrid", {
                                  attrs: {
                                    brands: _vm.brandQueryResult.object.items,
                                  },
                                })
                              : _vm.activeQueryResultView === "horizontal-view"
                              ? _c("HorizontalBrandTileGrid", {
                                  attrs: {
                                    brands: _vm.brandQueryResult.object.items,
                                  },
                                })
                              : _vm.activeQueryResultView === "table-view"
                              ? _c("BrandTable", {
                                  attrs: {
                                    brands: _vm.brandQueryResult.object.items,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }